import React, { useEffect, useState } from "react";
import Card from "../../assets/cards";
import { TCard, TPartner } from "../../assets/types";
import { Get } from "../../assets/api";
import PartnerFilter from "../../assets/header/partnerFilter";

export default function CardPage(): JSX.Element {
  const [cards, setCards] = useState<TCard[]>([]);
  const [partners, setPartners] = useState<TPartner[]>([]);

  const changeFilter = (partner: TPartner[]) => {
    const tmpArray: TCard[] = partner
      .filter((partner: TPartner) => !!partner.card)
      .map((partner: TPartner) => partner.card!);

    setCards(tmpArray);
  };

  useEffect(() => {
    Get({ path: "partner" })
      .then((partnerList) => {
        setPartners(partnerList.data);
        const tmpArray: TCard[] = partnerList.data
          .filter((partner: TPartner) => !!partner.card)
          .map((partner: TPartner) => partner.card!);

        setCards(tmpArray);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const getPartnerIDOverCard = (cardUuid: string): string | null => {
    const matchingPartner = partners.find((partner) => {
      return partner.card ? partner.card.uuid === cardUuid : false;
    });

    return matchingPartner ? matchingPartner.uuid : null;
  };

  return (
    <div className="cardPage">
      <PartnerFilter partnerList={partners} changeFilter={changeFilter} />
      {cards.map((partner: TCard, index: number) => (
        <Card
          uuid={getPartnerIDOverCard(partner.uuid) || partner.uuid}
          key={index}
          body={partner.body}
          companyImage={partner.companyImage}
          companyName={partner.companyName}
          header={partner.header}
          image={partner.image}
          tOne={{
            title: "Explore",
            navigate: `partner/${
              getPartnerIDOverCard(partner.uuid) || partner.uuid
            }`,
          }}
        />
      ))}
    </div>
  );
}
