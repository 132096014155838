import React, { useContext, useEffect, useState } from "react";
import { Delete, Get } from "../../assets/api";
import Table from "../../assets/table";
import { User } from "../../assets/types";
import { useParams } from "react-router-dom";
import { KeycloakContext } from "../../auth/keycloak";
import { FaTrash } from "react-icons/fa";

export default function EventMember(): JSX.Element {
  const [eventMember, setEventMember] = useState<User[]>([]);
  const { uuid } = useParams();
  const { keycloak } = useContext(KeycloakContext);

  const removeMember = (uuid: string) => {
    console.log(`Want to Remove the member ${uuid}`);
    Delete({ path: `event/member/${uuid}`, token: keycloak?.token })
      .then((response) => {
        console.log(response);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    Get({ path: `event/${uuid}/member`, token: keycloak?.token })
      .then((response) => {
        console.log("Get member List", response.data);
        setEventMember(response.data);
      })
      .catch((err) => {
        console.log("Error geting event members", err);
      });
  }, [uuid]);

  return (
    <div className="content">
      <Table
        what="Members"
        rows={eventMember}
        actions={[
          {
            label: "Entfernen",
            button: <FaTrash size={25} />,
            onClick: (uuid) => removeMember(uuid),
          },
        ]}
      />
    </div>
  );
}
