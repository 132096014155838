import React, { useEffect, useState, useContext } from "react";
import ReactMarkdown from "react-markdown";
import mermaid, { RenderResult } from "mermaid";
import { Get } from "../assets/api";
import { KeycloakContext } from "../auth/keycloak";
import rehypeRaw from "rehype-raw";
import rehypeStringify from "rehype-stringify";
import remarkGfm from "remark-gfm";

function MermaidPreview({ text }: { text: string }): JSX.Element {
  const previewRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    mermaid.mermaidAPI.initialize({
      startOnLoad: true,
      securityLevel: "loose",
      theme: "dark",
      logLevel: 5,
    });

    return () => {
      mermaid.mermaidAPI.reset();
    };
  }, []);

  useEffect(() => {
    const renderMermaid = async () => {
      if (previewRef.current && text) {
        try {
          const result: RenderResult = await mermaid.mermaidAPI.render(
            "preview",
            text
          );
          const svgString: string = result.svg;
          previewRef.current.innerHTML = svgString;
        } catch (error) {
          console.error("Error rendering Mermaid diagram:", error);
        }
      }
    };

    renderMermaid();
  }, [text]);

  return <div ref={previewRef} />;
}

const DocsPage: React.FC = () => {
  const [docMarkdown, setDocMarkdown] = useState<string | undefined>();
  const { keycloak } = useContext(KeycloakContext);

  useEffect(() => {
    const fetchDocs = async () => {
      try {
        const response = await Get({
          path: "documantation",
          token: keycloak?.token,
        });
        setDocMarkdown(response.data);
      } catch (error) {
        console.error("Error while fetching Docs:", error);
      }
    };

    fetchDocs();
  }, [keycloak?.token]);

  return (
    <ReactMarkdown
      className={"content"}
      components={{
        code: ({ className, children, ...props }) => {
          const match = /language-mermaid/.exec(className || "");
          return match && children ? (
            <div
              style={{
                width: "90vw",
                backdropFilter: "blur(10px)",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MermaidPreview text={children.toString()} />
            </div>
          ) : (
            <code {...props}>{children}</code>
          );
        },
      }}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw, rehypeStringify]}
    >
      {docMarkdown}
    </ReactMarkdown>
  );
};

export default DocsPage;
