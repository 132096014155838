import React from "react";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <footer>
      <p
        onClick={() => {
          navigate("/impressum");
        }}
      >
        Impressum
      </p>
      <p>Datenschutz</p>
      <p>AGB</p>
      <p>Kontakt</p>
      <p>News</p>
      <p>Jobs</p>
    </footer>
  );
}
