import React from "react";
import csvDownload from "json-to-csv-export";
import { FaDownload } from "react-icons/fa";

export interface CsvDownloadProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  data: any[];
  filename?: string | undefined;
  delimiter?: string | undefined;
  headers?: string[] | undefined;
}

export const CsvDownloadButton = (props: CsvDownloadProps) => {
  const { data, filename, delimiter, headers, ...others } = props;

  return (
    <span
      style={{ cursor: "pointer" }}
      className="table-row-count"
      onClick={() => csvDownload({ data, filename, delimiter, headers })}
      {...others}
    >
      <FaDownload />
    </span>
  );
};
