import React from "react";
import { TInput } from "../types";

export default function Input({
  type,
  onChange,
  onClick,
  title,
  style,
}: TInput): JSX.Element {
  return (
    <div>
      <label className="input">
        <input
          className="input__field"
          style={style}
          type={type}
          placeholder=" "
          onChange={onChange}
          onClick={onClick}
        />
        <span className="input__label">{title}</span>
      </label>
    </div>
  );
}
