import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { TCalendar } from "../types";

export default function Calendar({ eventList }: TCalendar) {
  const handleEventClick = (info: any) => {
    console.log(
      "Event clicked:",
      info.event.startStr,
      info.event.endStr,
      info.event.uuid
    );
  };

  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        events={eventList}
        themeSystem="bootstrap5"
        nowIndicator
        timeZone="Europe/Berlin"
        locale={"de"}
        height={"auto"}
        eventClick={handleEventClick}
        slotMinTime="06:00:00"
        slotMaxTime="23:01:00"
      />
    </div>
  );
}
