import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Event, User } from "../../assets/types";
import { Get } from "../../assets/api";
import { ParagraphItem } from "../partner/about";
import { KeycloakContext } from "../../auth/keycloak";

export default function GalleryPage(): JSX.Element {
  const [event, setEvent] = useState<Event>();
  const [eventMembers, setEventMembers] = useState<User[]>();
  const { uuid } = useParams();
  const { keycloak, profile } = useContext(KeycloakContext);

  useEffect(() => {
    console.log(uuid);
    Get({ path: `event/${uuid}` })
      .then((response) => {
        console.log(response.data);
        setEvent(response.data);
      })
      .catch((err: any) => {
        console.error(err);
      });
    profile && keycloak?.realmAccess?.roles.includes("event-management")
      ? Get({ path: `event/${uuid}/member` })
          .then((memberList) => {
            setEventMembers(memberList.data);
          })
          .catch((err) => {
            console.error("Error Fetching Data");
          })
      : console.log("Not full Access");
  }, [uuid]);

  if (event) {
    return (
      <div className="content">
        <div className="thumbnail-container">
          <img src={event.about?.thumb.src} alt={event.about?.thumb.alt} />
          <h1>{event.title}</h1>
        </div>
        <div className="awards">
          {event.category ? (
            <div className="selected-tags">
              {event.category.map((tag) => (
                <div
                  key={tag.value}
                  className="tag"
                  style={{
                    backgroundColor: tag.color,
                  }}
                >
                  {tag.label}
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="aboutBody">
          <div className="aboutContainer">{event.body}</div>
          {event.about ? (
            event.about.about.map((paragraph, index) => (
              <ParagraphItem {...paragraph} key={index} />
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
