import React, {
  type ReactNode,
  useState,
  useEffect,
  useCallback,
  createContext,
} from "react";
import Keycloak, { type KeycloakProfile, KeycloakInstance } from "keycloak-js";
import * as Sentry from "@sentry/react";

export type Profile = KeycloakProfile | undefined;
export type TKeycloak = KeycloakInstance | undefined;

export const KeycloakContext = createContext<{
  profile: Profile;
  keycloak: TKeycloak;
}>({
  profile: undefined,
  keycloak: undefined,
});

export interface KeycloakContextProviderProps {
  children: ReactNode;
}

const defaultKeycloakConfig = {
  url: "https://keycloak.medocino.net/auth",
  realm: "medocino",
  clientId: "medocino-event",
  clientSecret: "Yy3JA6X7N3nAHwhKDoEj5uLBjeIMcnr5",
};

export const KeycloakAuth = ({
  children,
}: KeycloakContextProviderProps): JSX.Element => {
  const [profile, _setProfile] = useState<KeycloakProfile | undefined>();
  const [keycloak, _setKeycloak] = useState<Keycloak | undefined>();

  const setUserInfo = (info: any): void => {
    if (info) {
      console.log("[Keycloak] userInfo", info);
      Sentry.setUser(info);
      Sentry.captureMessage("Session started");

      _setProfile(info);
    } else {
      Sentry.setUser(null);
      _setProfile(undefined);
    }
  };
  const setProfile = useCallback((profile: Profile): void => {
    const merged = profile ? ({ ...profile } as any) : null;
    if (merged) {
      delete merged.userProfileMetadata;
      delete merged.attributes;
      setUserInfo(merged);
    } else {
      setUserInfo(undefined);
    }
  }, []);

  const fetchProfile = (keycloak: Keycloak): void => {
    if (!profile) {
      keycloak
        .loadUserProfile()
        .then((profile) => {
          console.debug("[Keycloak] Profile values: ", profile);
          setProfile(profile);
        })
        .catch(function () {
          console.warn("[Keycloak] Failed to load profile");
          setProfile(undefined);
        });
    }
  };

  const init = (cfg: any): Keycloak => {
    const result = new Keycloak(cfg);

    result.onAuthSuccess = () => {
      console.log("keycloak", "[Keycloak] Auth Success");
    };

    result.onAuthError = (errorData) => {
      console.error("keycloak", `[Keycloak] ${JSON.stringify(errorData)}`);
    };
    result.onTokenExpired = () => {
      console.log("keycloak", "[Keycloak] Access token expired.");
    };

    result.onActionUpdate = (status) => {
      switch (status) {
        case "success":
          console.debug("keycloak", "[Keycloak] Action completed successfully");
          break;
        case "cancelled":
          console.debug("keycloak", "[Keycloak] Action cancelled by user");
          break;
        case "error":
          console.debug("keycloak", "[Keycloak] Action failed");
          break;
      }
    };

    result
      .init({
        responseMode: "fragment",
        flow: "standard",
        enableLogging: true,
      })
      .then(async (authenticated) => {
        console.debug(
          "[Keycloak] Init Success (" +
          (authenticated ? "Authenticated" : "Not Authenticated") +
          ")"
        );
        _setKeycloak(result);
        authenticated
          ? fetchProfile(result)
          : console.warn("[Keycloak] USER IS NOT SIGNIN");
      })
      .catch((err) => {
        setProfile(undefined);
        console.warn(
          "Keycloak Init Error",
          `[Keycloak] ${JSON.stringify(err)}`
        );
      });

    return result;
  };

  useEffect(() => {
    if (profile) {
      console.log("[Keycloak] profile found");
      return;
    }

    if (!keycloak) {
      console.log("[Keycloak] init keycloak");
      init(defaultKeycloakConfig);
    }
  }, [keycloak, profile, setProfile]);

  if (keycloak) {
    if (profile !== undefined) {
      return (
        <KeycloakContext.Provider value={{ profile, keycloak }}>
          {children}
        </KeycloakContext.Provider>
      );
    }

    return (
      <KeycloakContext.Provider value={{ profile, keycloak }}>
        {children}
      </KeycloakContext.Provider>
    );
  } else {
    return <>{children}</>;
  }
};
