import React, { useEffect, useState } from "react";
import { TToast } from "../types";
import { AiOutlineCloseCircle } from "react-icons/ai";

export default function Toast({ msg, visible, type }: TToast): JSX.Element {
  const [animation, setAnimation] = useState<boolean>(true);
  useEffect(() => {
    setAnimation(true);
  }, [visible]);
  return (
    <figure
      className="notification"
      style={{
        animation:
          visible && msg !== null && animation ? "fade-in 5s linear" : "",
      }}
    >
      <div className="notification__body">
        <div className="notification__description">
          <div
            className="icon__wrapper"
            style={{
              backgroundColor: `var(--${type})`,
            }}
          >
            {type === "succsess" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
            ) : type === "error" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="4" y1="12" x2="20" y2="12"></line>
              </svg>
            )}
          </div>
          {msg}
        </div>
        <button
          className="notification__button"
          onClick={() => {
            setAnimation(false);
          }}
        >
          <AiOutlineCloseCircle />
        </button>
      </div>
      <div
        className="notification__progress"
        style={{
          background: `linear-gradient(
            to right,
            var(--toast-background),
            var(--${type})
          );`,
          transform: "scaleX(0)",
          animation:
            visible && msg !== null && animation
              ? "progress 2.5s 0.3s linear"
              : "",
        }}
      ></div>
    </figure>
  );
}
