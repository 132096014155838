import axios, { AxiosError } from "axios";
import { IAxios, IReturn } from "./types";

export const url = "/api/";

const makeRequest = async (
  method: "get" | "post" | "put" | "delete",
  { path, token, value }: IAxios
): Promise<IReturn> => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  try {
    const response = await axios[method](url + path, value);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error: unknown) {
    const axiosError = error as AxiosError;
    // Sentry.captureException(error);
    return {
      status: axiosError.status || 500,
      data: axiosError.message || "An error occurred",
    };
  }
};

export const Get = async ({ path, token }: IAxios): Promise<IReturn> => {
  return makeRequest("get", { path, token });
};

export const Post = async ({
  path,
  token,
  value,
}: IAxios): Promise<IReturn> => {
  return makeRequest("post", { path, token, value });
};

export const Put = async ({ path, token, value }: IAxios): Promise<IReturn> => {
  return makeRequest("put", { path, token, value });
};

export const Delete = async ({ path, token }: IAxios): Promise<IReturn> => {
  return makeRequest("delete", { path, token });
};
