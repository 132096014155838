import React from "react";
import {
  AiOutlinePhone,
  AiOutlineMail,
  AiOutlineEnvironment,
} from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";

const Impressum = () => {
  return (
    <div className="content">
      <h1>Impressum</h1>
      <div className="contact-item">
        <AiOutlineEnvironment size={30} />
        <p>
          medocino Hamburg GmbH
          <br />
          Wilma-Witte-Stieg 1
          <br />
          22047 Hamburg
        </p>
      </div>

      <div className="contact-details">
        <div className="contact-item">
          <AiOutlinePhone size={30} />
          <p>Festnetz: +123 456 789</p>
        </div>
        <div className="contact-item">
          <FiPhoneCall size={30} />
          <p>Mobil: +987 654 321</p>
        </div>
        <div className="contact-item">
          <AiOutlineMail size={30} />
          <p>Email: contact@example.com</p>
        </div>
      </div>
      <div className="contact-details">
        <div className="contact-item">
          <p>Sitz der Gesellschaft:</p>
          <p>Hamburg</p>
        </div>
        <div className="contact-item">
          <p>Handelsregistereintrag:</p>
          <p>Amtsgericht Hamburg HRB 134661</p>
        </div>
        <div className="contact-item">
          <p>Umsatzsteuer-Identifikationsnummer:</p>
          <p>DE297894034</p>
        </div>
        <div className="contact-item">
          <p>Geschäftsführung: </p>
          <p>Stephan Beckmann, Christian Nützmann</p>
        </div>
      </div>
    </div>
  );
};

export default Impressum;
