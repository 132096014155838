import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import MedoLogo from "../images/medo.png";
import { useNavigate } from "react-router-dom";
import { KeycloakContext } from "../../auth/keycloak";
import { FaAdjust } from "react-icons/fa";
import { TTheme } from "../types";

export default function Header() {
  const { keycloak, profile } = useContext(KeycloakContext);
  const navigate = useNavigate();
  const [selectedTheme, setSelectedTheme] = useState<TTheme>("medocino");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [header, setHeader] = useState<string>("open");

  const handleThemeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedTheme(e.target.value as TTheme);
  };

  type TThemeOptions = {
    value: TTheme;
    label: string;
  };
  const themeOptions: TThemeOptions[] = [
    { value: "light", label: "Light Theme" },
    { value: "dark", label: "Dark Theme" },
    { value: "medocino", label: "Medocino Theme" },
  ];

  useEffect(() => {
    const bodyElement = document.body;
    if (selectedTheme === "medocino") {
      bodyElement.classList.remove("light");
      bodyElement.classList.remove("dark");
    } else if (selectedTheme === "light") {
      bodyElement.classList.remove("medocino");
      bodyElement.classList.remove("dark");
    } else {
      bodyElement.classList.remove("light");
      bodyElement.classList.remove("medocino");
    }
    bodyElement.classList.add(selectedTheme);
  }, [selectedTheme]);
  return (
    <header className={header === "" ? "open" : ""}>
      <img
        className="logo"
        id="hiddenNav"
        src={MedoLogo}
        alt="Logo"
        onClick={async () => {
          header === "open" ? setHeader("") : setHeader("open");
        }}
      />
      <ul>
        <li
          onClick={async () => {
            navigate("/events");
          }}
        >
          Events
        </li>
        <li
          onClick={async () => {
            navigate("/partners");
          }}
        >
          Partner
        </li>
        {profile &&
        keycloak?.realmAccess?.roles.includes("default-roles-medocino") ? (
          <>
            <li
              onClick={async () => {
                navigate("/event/admin");
              }}
            >
              Event-Admin
            </li>
            <li
              onClick={async () => {
                navigate("/documentation");
              }}
            >
              Documentation
            </li>
          </>
        ) : (
          <></>
        )}
      </ul>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="theme-switcher">
          <div onClick={() => setDropdownVisible(!isDropdownVisible)}>
            <FaAdjust size={25} />
          </div>
          <select
            value={selectedTheme}
            onChange={(e) => handleThemeChange(e)}
            style={{ display: isDropdownVisible ? "block" : "none" }}
          >
            {themeOptions.map((theme) => (
              <option key={theme.value} value={theme.value}>
                {theme.label}
              </option>
            ))}
          </select>
        </div>
        {profile ? (
          <div className="avatar">
            <img
              src="https://cdn-icons-png.flaticon.com/512/2815/2815428.png"
              alt="Avatar"
            />
          </div>
        ) : (
          <button
            title="Login"
            onClick={() => keycloak?.login()}
            id="keycloakAuth"
          >
            Login
          </button>
        )}
      </div>
    </header>
  );
}
