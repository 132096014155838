import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { BsGrid, BsCheckCircle, BsXCircle } from "react-icons/bs";
import { BiCalendar } from "react-icons/bi";
import {
  AiOutlineSearch,
  AiOutlineCloseCircle,
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineClockCircle,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { Event, TDateSort, TViewTypes, TagOption } from "../types";
import { Get } from "../api";
import { IoEyeOutline } from "react-icons/io5";
import Input from "../input/input";

function DateSortIcon({ type }: { type: TDateSort }): JSX.Element {
  if (type === "up") {
    return (
      <div>
        <AiOutlineClockCircle size={25} />
        <AiOutlineArrowUp size={25} />
      </div>
    );
  } else if (type === "down") {
    return (
      <div>
        <AiOutlineClockCircle size={25} />
        <AiOutlineArrowDown size={25} />
      </div>
    );
  } else {
    return (
      <div>
        <AiOutlineClockCircle size={25} />
      </div>
    );
  }
}

export default function EventFilter({
  allEvents,
  eventArray,
  changeCalendarVisibility,
  changeView,
  createEvent,
}: {
  allEvents: Event[];
  eventArray: (event: Event[]) => void;
  changeCalendarVisibility?: () => void;
  changeView: (viewType: TViewTypes) => void;
  createEvent?: boolean;
}): JSX.Element {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [dateSort, setDateSort] = useState<TDateSort>("none");
  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [selectedYear, setSelectedYear] = useState<string | null>(null);
  const [joinable, setJoinable] = useState<boolean>(false);
  const [prevFilteredEvents, setPrevFilteredEvents] = useState<Event[]>([]);
  const [allTagOptions, setAllTagOptions] = useState<TagOption[]>([]);
  const [view, setView] = useState<TViewTypes>("widget");
  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  const changeDateSort = (type: TDateSort) => {
    if (type === "none") {
      setDateSort("down");
    } else if (type === "down") {
      setDateSort("up");
    } else {
      setDateSort("none");
    }
  };

  const tagOptions = allTagOptions.filter(
    (tag) => !selectedTags.includes(tag.value)
  );

  const toggleTag = (tag?: string) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag!)
        ? prevTags.filter((prevTag) => prevTag !== tag)
        : [...prevTags, tag!]
    );
  };

  const toggleOptions = () => {
    setShowOptions((prevShowOptions) => !prevShowOptions);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredEvents = allEvents
    .filter((event) => {
      const nameMatch = event.title
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const dayMatch =
        !selectedDay || event.start?.split("-")[2] === selectedDay;
      const monthMatch =
        !selectedMonth || event.start?.split("-")[1] === selectedMonth;
      const yearMatch =
        !selectedYear || event.start?.split("-")[0] === selectedYear;
      const memberMatch = joinable
        ? event.members.maxParticipants - event.members.participants !== 0
        : true;
      const upcomingMatch = joinable
        ? event.start
          ? new Date(event.start) >= new Date()
          : true
        : true;

      if (event.category && selectedTags.length !== 0) {
        const categoryMatch = event.category.some((category) =>
          selectedTags.includes(category.value)
        );

        return (
          nameMatch &&
          dayMatch &&
          monthMatch &&
          yearMatch &&
          memberMatch &&
          upcomingMatch &&
          categoryMatch
        );
      } else {
        return (
          nameMatch &&
          dayMatch &&
          monthMatch &&
          yearMatch &&
          memberMatch &&
          upcomingMatch
        );
      }
    })
    .sort((a, b) => {
      if (dateSort === "up") {
        return new Date(a.start!).getTime() - new Date(b.start!).getTime();
      } else if (dateSort === "down") {
        return new Date(b.start!).getTime() - new Date(a.start!).getTime();
      } else {
        return 0;
      }
    });

  const deepEqual = (obj1: any, obj2: any): boolean => {
    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    if (!deepEqual(filteredEvents, prevFilteredEvents)) {
      setPrevFilteredEvents(filteredEvents);
      eventArray(filteredEvents);
    }
  }, [filteredEvents, eventArray, prevFilteredEvents]);

  useEffect(() => {
    Get({ path: "category" }).then((categorys) => {
      console.log(categorys);
      if (categorys.status !== 500) {
        setAllTagOptions(categorys.data);
      } else {
        setAllTagOptions([]);
      }
    });
  }, []);

  return (
    <div className="filterBar" style={{ gridArea: "filterNav" }}>
      <Input
        title={<AiOutlineSearch size={25} />}
        onChange={handleInputChange}
      />

      <BsGrid onClick={toggleOptions} size={25} />
      <div className="multiselect-tag-list">
        {showOptions && (
          <div className="tag-container">
            {tagOptions.map((tag) => (
              <div
                key={tag.value}
                className="tag"
                onClick={() => toggleTag(tag.value)}
                style={{ backgroundColor: tag.color }}
              >
                {tag.label}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="selected-tags">
        {selectedTags.map((tag) => (
          <div
            key={tag}
            className="tag selected"
            onClick={() => toggleTag(tag)}
            style={{
              backgroundColor: allTagOptions.find((t) => t.value === tag)
                ?.color,
            }}
          >
            <AiOutlineCloseCircle size={15} /> {tag}
          </div>
        ))}
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Input
          title="Tag"
          onChange={(e) => setSelectedDay(e.target.value)}
          type="number"
        />
        <label className="input">
          <select
            className="input__field"
            value={selectedMonth || ""}
            onChange={(e) => setSelectedMonth(e.target.value)}
          >
            <option value=""></option>
            {months.map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>
          <span className="input__label">Monat</span>
        </label>{" "}
        <Input
          title="Jahr"
          onChange={(e) => setSelectedYear(e.target.value)}
          type="number"
        />
      </div>
      <button
        style={{ border: "none", background: "none" }}
        onClick={() => {
          changeDateSort(dateSort);
        }}
      >
        <DateSortIcon type={dateSort} />
      </button>
      {changeCalendarVisibility ? (
        <button
          style={{ border: "none", background: "none" }}
          onClick={() => {
            changeCalendarVisibility();
          }}
        >
          <BiCalendar />
        </button>
      ) : (
        <></>
      )}
      {createEvent ? (
        <button
          onClick={() => {
            document.querySelector("dialog")?.showModal();
            // navigate("/event/admin/create");
          }}
          style={{ border: "none", background: "none" }}
        >
          <AiOutlinePlusCircle />
        </button>
      ) : (
        <></>
      )}
      <button
        onClick={() => {
          setJoinable(!joinable);
        }}
        style={{ border: "none", background: "none" }}
      >
        {joinable ? <BsCheckCircle /> : <BsXCircle />}
      </button>

      <button
        style={{ border: "none", background: "none" }}
        onClick={() => {
          view === "widget" ? setView("table") : setView("widget");
          view === "widget" ? changeView("table") : changeView("widget");
        }}
      >
        <IoEyeOutline />
      </button>
    </div>
  );
}
