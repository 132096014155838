import React from "react";
import { TInput, TTextArea } from "../types";

export default function Textarea({
  onChange,
  onClick,
  title,
  style,
  rows,
  cols,
}: TTextArea): JSX.Element {
  return (
    <label className="input">
      <textarea
        className="input__field"
        style={style}
        placeholder=" "
        onChange={onChange}
        onClick={onClick}
        rows={rows ? rows : 4}
        cols={cols ? cols : 4}
      />
      <span className="input__label">{title}</span>
    </label>
  );
}
