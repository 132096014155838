import React from "react";
import { TCheckbox } from "../types";

export default function Checkbox({
  defaultCheck,
  onClick,
  title,
  id,
  checked,
  onChange,
  style,
}: TCheckbox): JSX.Element {
  return (
    <div className="checkbox-wrapper" onClick={onClick}>
      <input
        onChange={onChange}
        className="inp-cbx"
        id={id}
        type="checkbox"
        defaultChecked={defaultCheck}
        checked={checked}
      />
      <label className="cbx" htmlFor={id}>
        <span style={style}>
          <svg width="12px" height="10px">
            <use xlinkHref="#check"></use>
          </svg>
        </span>
        <span>{title}</span>
      </label>
      <svg className="inline-svg">
        <symbol id="check" viewBox="0 0 12 10">
          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
        </symbol>
      </svg>
    </div>
  );
}
