import React, { useEffect, useState } from "react";
import { CreateRowProps, TTable, TagOption } from "../types";
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from "react-icons/io5";
import { Get } from "../api";
import { FaDownload, FaSortAlphaUp, FaSortAlphaDown } from "react-icons/fa";
import { CsvDownloadButton } from "./downloadToCsv";

function CreateRow<T extends Record<string, any>>({
  item,
  actions,
  allTagOptions,
}: CreateRowProps<T>): JSX.Element {
  const columns = (Object.keys(item) as Array<keyof T>).filter(
    (column) => column !== "uuid"
  );

  return (
    <tr key={(item as any).uuid}>
      {actions &&
        actions.map((action, index) => (
          <td key={index}>
            <button
              style={{
                width: "fit-content",
                background: "none",
                boxShadow: "none",
              }}
              onClick={() => action.onClick((item as any).uuid)}
            >
              {action.button}
            </button>
          </td>
        ))}
      {columns.map((column, index) => (
        <td className={index === 0 ? "sticky-left" : ""} key={index}>
          {renderCellValue(column as string, item[column], allTagOptions)}
        </td>
      ))}
    </tr>
  );

  function renderCellValue(
    column: string,
    value: any,
    allTagOptions: TagOption[]
  ): JSX.Element {
    const formatDateTime = (datumString: string) => {
      const date = datumString.split("T")[0];
      const clock = datumString.split("T")[1].split(".")[0];

      const day = date.split("-")[2];
      const month = date.split("-")[1];
      const year = date.split("-")[0];

      const hour = clock.split(":")[0];
      const minutes = clock.split(":")[1];

      const formatierterString = `${day}.${month}.${year} ${hour}${
        minutes !== "00" ? `:${minutes}` : ""
      } Uhr`;

      return formatierterString;
    };

    if (
      column === "participation" ||
      column === "public" ||
      column === "allowPicture"
    ) {
      return value === "1" || value === "true" || value === true ? (
        <IoCheckmarkCircleOutline
          size={20}
          style={{ background: "green", borderRadius: "100%" }}
        />
      ) : (
        <IoCloseCircleOutline
          size={20}
          style={{ background: "red", borderRadius: "100%" }}
        />
      );
    }

    if (column === "start" || column === "end") {
      return <>{formatDateTime(value)}</>;
    }

    if (column === "category") {
      console.log(value);
      return (
        <div className="selected-tags">
          {value.map((tag: any, index: number) => (
            <div
              key={index}
              className="tag selected"
              style={{
                backgroundColor: allTagOptions.find(
                  (t) => t.label === tag.category_label
                )?.color,
              }}
            >
              {tag.category_label}
            </div>
          ))}
        </div>
      );
    }

    if (column === "members") {
      return (
        <>
          {value.participants}/{value.maxParticipants}
        </>
      );
    }
    if (typeof value === "boolean") {
      return value ? (
        <IoCheckmarkCircleOutline size={20} color="green" />
      ) : (
        <IoCloseCircleOutline size={20} color="red" />
      );
    }

    return typeof value === "object" ? JSON.stringify(value) : value;
  }
}

function SVGIcon(): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    ></svg>
  );
}

export default function Table<T extends Record<string, any>>({
  rows,
  what,
  actions,
}: TTable<T>): JSX.Element {
  const [allTagOptions, setAllTagOptions] = useState<TagOption[]>([]);
  const [sortColumn, setSortColumn] = useState<keyof T | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const handleSort = (column: keyof T) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const sortedRows = rows.slice().sort((a, b) => {
    if (sortColumn) {
      const aValue = String(a[sortColumn]);
      const bValue = String(b[sortColumn]);
      const order = sortOrder === "asc" ? 1 : -1;
      return aValue.localeCompare(bValue) * order;
    }
    return 0;
  });

  useEffect(() => {
    Get({ path: "category" }).then((categorys) => {
      console.log(categorys);
      if (categorys.status !== 500) {
        setAllTagOptions(categorys.data);
      } else {
        setAllTagOptions([]);
      }
    });
  }, []);
  if (!rows.length) {
    return <div>No data available</div>;
  }
  const item = rows[0];
  const columns = (Object.keys(item) as Array<keyof T>).filter(
    (column) => column !== "uuid"
  );

  return (
    <div className="table-widget">
      <span className="caption-container">
        <span className="table-title">
          <SVGIcon />
          {what}list
        </span>
        <span className="table-row-count">
          {rows.length} {what}
        </span>
        <CsvDownloadButton data={rows} filename={what} />
      </span>
      <div className="table-wrapper">
        <table style={{ width: "100%", tableLayout: "auto" }}>
          <thead>
            <tr>
              {actions &&
                actions.map((action, index) => (
                  <th
                    style={{
                      width: `calc(100% / ${actions.length + columns.length})`,
                    }}
                    key={index}
                  >
                    {action.label}
                  </th>
                ))}
              {columns.map((column, index) => (
                <th
                  style={{
                    width: `calc(100% / ${
                      actions ? actions.length + columns.length : columns.length
                    })`,
                  }}
                  key={index}
                  className={index === 0 ? "sticky-left" : ""}
                  onClick={() => handleSort(column)}
                >
                  {String(column)}
                  {sortColumn === column ? (
                    sortOrder === "desc" ? (
                      <FaSortAlphaUp />
                    ) : (
                      <FaSortAlphaDown />
                    )
                  ) : (
                    <></>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedRows.map((row, index) => (
              <CreateRow
                key={index}
                item={row}
                actions={actions}
                allTagOptions={allTagOptions}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
