import React from "react";
import CardPage from "./partner/card";
export default function InfoPage({
  title,
  body,
}: {
  title: string;
  body: string | JSX.Element;
}): JSX.Element {
  return (
    <div className="content">
      <div className="title">{title}</div>
      <div className="text">{body}</div>
      <CardPage />
    </div>
  );
}
