import React, { useEffect, useState } from "react";
import "../assets/images/animated-logo/large/index.css";
import { useParams } from "react-router-dom";
import { Get, Post } from "../assets/api";

export default function StatusPage({
  text,
  action,
}: {
  text: string | JSX.Element;
  action?: "cancle" | "success";
}): JSX.Element {
  const { event, member } = useParams();
  const [eventValue, setEventValue] = useState<any>();

  const formatDateTime = (datumString: string) => {
    const date = datumString.split("T")[0];
    const clock = datumString.split("T")[1].split(".")[0];

    const day = date.split("-")[2];
    const month = date.split("-")[1];
    const year = date.split("-")[0];

    const hour = clock.split(":")[0];
    const minutes = clock.split(":")[1];

    const formatierterString = `${day}.${month}.${year} ab ${hour}${minutes !== "00" ? `:${minutes}` : ""
      } Uhr`;

    return formatierterString;
  };

  const CancleMembership = async () => {
    const e = await Get({ path: `event/${event}` });
    setEventValue(e.data);
    console.log("e.data : ", e.data);
  };
  useEffect(() => {
    if (action === "cancle") {
      console.log({ event: event, member: member });
      CancleMembership();
      Post({
        path: `event/cancle/${event}/${member}`,
      })
        .then((response) => {
          console.log("Remove from event", response);
        })
        .catch((err: any) => {
          console.error("Remove from event", err);
        });
    } else {
      Get({ path: `event/${event}` })
        .then((eventValue) => {
          console.log("Event Value: ", eventValue.data);
          setEventValue(eventValue.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <div className="content">
      {text}
      {eventValue && action === "cancle" && (
        <div style={{ margin: "8em auto", textAlign: "center" }}>
          <p>
            Vielen Dank für Ihre Absage zu unsererem medocino Steak & Storage Event am {formatDateTime(eventValue?.data.start)}.
          </p>
          <p>Wie schade, dass Sie nicht dabei sein können.</p>
          <p>
            Falls Sie es sich anders überlegen, können Sie sich
            <a href="https://events.medocino.net"> hier</a> wieder anmelden.
          </p>
        </div>
      )}
      {eventValue && action === "success" && (
        <div style={{ margin: "8em auto", textAlign: "center" }}>
          <p>
            Vielen Dank für Ihre Anmeldung zu unsererem medocino Steak & Storage Event am {formatDateTime(eventValue.data.start)}.
          </p>
          <p>
            Wir freuen uns, Sie begrüßen zu
            dürfen.
          </p>
          <p>Ihr medocino Team</p>
        </div>
      )}
    </div>
  );
}
