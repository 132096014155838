import React, { useState } from "react";
import { Get, Post, url } from "../../assets/api";
import { useNavigate, useParams } from "react-router-dom";
import MedoLogo from "../../assets/images/medo.png";
import Input from "../../assets/input/input";
import Checkbox from "../../assets/input/checkbox";
import Toast from "../../assets/toast";

export default function RegistrationForm(): JSX.Element {
  const [participation, setParticipation] = useState<boolean | null>(null);
  const [participationMissing, setParticipationMissing] =
    useState<boolean>(false);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [firstNameMissing, setFirstNameMissing] = useState<boolean>(false);
  const [sureName, setSureName] = useState<string | null>(null);
  const [sureNameMissing, setSureNameMissing] = useState<boolean>(false);
  const [company, setCompany] = useState<string | null>(null);
  const [companyMissing, setCompanyMissing] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string | null>("");
  const [toast, setToast] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>("Anmelden");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);
  const [emailMissing, setEmailMissing] = useState<boolean>(false);
  const [allowPicture, setAllowPicture] = useState<boolean>(false);
  const [gender, setGender] = useState<"male" | "female">();
  const [genderMissing, setGenderMissing] = useState<boolean>(false);
  const [toastType, setToastType] = useState<"error" | "succsess" | "warn">(
    "succsess"
  );
  const { uuid } = useParams();

  const navigate = useNavigate();

  const changeButton = () => {
    if (
      firstName === null ||
      firstName === "" ||
      sureName === null ||
      sureName === "" ||
      company === null ||
      company === "" ||
      email === null ||
      email === "" ||
      participation === null ||
      !isValidEmail(email)
    ) {
      return {
        cursor: "not-allowed",
        backgroundColor: "var(--background-tertiary)",
      };
    } else {
      return {
        border: "none",
        cursor: "pointer",
        backgroundColor: "var(--background-primary)",
      };
    }
  };
  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.warn(emailRegex.test(email));
    return emailRegex.test(email);
  };
  const checkValid = () => {
    if (
      firstName === null ||
      firstName === "" ||
      sureName === null ||
      sureName === "" ||
      company === null ||
      company === "" ||
      email === null ||
      email === "" ||
      participation === null ||
      gender === null ||
      gender === undefined
    ) {
      if (gender === null || gender === undefined) {
        activateToast("Bitte geben Sie Ihr Geschlecht an.");
        setGenderMissing(true);
        setToastType("warn");
      } else {
        setGenderMissing(false);
      }
      if (firstName === null || firstName === "") {
        activateToast("Bitte geben Sie Ihren Vornamen an.");
        setFirstNameMissing(true);
        setToastType("warn");
      } else {
        setFirstNameMissing(false);
      }
      if (sureName === null || sureName === "") {
        activateToast("Bitte geben Sie Ihren Nachnamen an.");
        setToastType("warn");
        setSureNameMissing(true);
      } else {
        setSureNameMissing(false);
      }
      if (company === null || company === "") {
        activateToast("Bitte geben Sie Ihr Unternhemen an.");
        setToastType("warn");
        setCompanyMissing(true);
      } else {
        setCompanyMissing(false);
      }
      if (email === null || email === "") {
        activateToast("Bitte geben Sie Ihre E-Mail an.");
        setToastType("warn");
        setEmailMissing(true);
      } else {
        setEmailMissing(false);
      }
      if (participation === null) {
        activateToast("Bitte geben Sie Ihren Teilnahmestatus an.");
        setToastType("warn");
        setParticipationMissing(true);
      } else {
        setParticipationMissing(false);
      }
    } else {
      if (!isValidEmail(email)) {
        activateToast("Bitte geben Sie eine gültige E-Mail an.");
        setToastType("warn");
        setEmailMissing(true);
      } else {
        setButtonText("Danke");
        setIsActive(true);
      }
    }
  };

  const cleanToast = () => {
    setTimeout(() => {
      setToastMsg(null);
      setToast(false);
    }, 3000);
  };

  const activateToast = (msg: string) => {
    setToast(true);
    setToastMsg(msg);
    cleanToast();
  };
  // Temporary Just internal!
  const checkUUID = async () => {
    if (!uuid) {
      const event = await Get({ path: "event/tmp" });
      console.log(event.data.uuid);
      return event.data.uuid;
    } else {
      return uuid;
    }
  };

  const register = async () => {
    const user = {
      firstName,
      sureName,
      company,
      email,
      participation,
      allowPicture,
      gender,
    };
    checkValid();

    if (
      company !== null &&
      sureName !== null &&
      firstName !== null &&
      email !== null &&
      email !== "" &&
      company !== "" &&
      sureName !== "" &&
      firstName !== "" &&
      isValidEmail(email)
    ) {
      const uuidValue = await checkUUID();
      console.log(`event/${uuidValue}/register`);
      console.warn("TRIGGER EVENT POST");
      const result = await Post({
        path: `event/${uuidValue}/register`,
        value: user,
      });
      setTimeout(() => {
        console.log("Waited for 3 seconds");
        setButtonText("Submit");
        setIsActive(false);
        navigate(`/event/success/${uuidValue}`);
      }, 500);

      if (result.status === 304) {
        console.log("[REGISTRATION STATUS] Nothing changed.");
        activateToast("Es hat sich nichts geändert!");
        setToastType("warn");
      } else if (
        result.status === 201 &&
        result.data === "User joined the Event"
      ) {
        console.log("[REGISTRATION STATUS] User want to Join the Event!");
        activateToast("Ihre Einladung wurde versendet");
        setToastType("succsess");
      } else if (
        result.status === 201 &&
        result.data === "User leaved the Event"
      ) {
        console.log("[REGISTRATION STATUS] User leaves the Event!");
        activateToast("Ihre änderungen wurden vorgenommen!");
        setToastType("succsess");
      } else if (result.status === 500) {
        if (result.data === "Request failed with status code 304") {
          console.log("[REGISTRATION STATUS] Nothing changed.");
          activateToast("Es hat sich nichts geändert!");
          setToastType("error");
        } else {
          console.log("[REGISTRATION STATUS] Server Side Error");
          activateToast("Etwas ist schief gelaufen!");
          setToastType("error");
        }
      } else {
        console.log("[REGISTRATION STATUS] User registered successfully.");
        activateToast("Ihre Einladung wurde versendet");
      }
    }
  };

  return (
    <>
      <div className="registrationForm">
        <div className="infoSight">
          <h3>medocino Steak & Storage</h3>
          <p>
            <strong>Wir laden Sie herzlich zu unserem Event  medocino Steak & Storage am</strong>
            <br />
            <strong>
              Donnerstag, den 05.09.2024 von 16:00 Uhr bis ca. 20:00 Uhr in Hamburg ein!
            </strong>
          </p>
          <br />
          <div className="infoSightImgContainer">
            <img
              src={`${url}images/partner/hpe.svg`}
              alt="HPE"
            />
            <img
              style={{ marginTop: "-30px" }}
              src={`${url}images/medo.png`}
              alt="medocino"
            />
            <img
              style={{ marginTop: "-30px" }}
              src={`${url}images/partner/zerto.png`}
              alt="Zetro"
            />
          </div>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p>
            Über den Dächern Hamburgs wollen wir Ihnen nicht nur einen Ausblick auf das Panorama
            der schönsten Stadt im Norden geben, sondern auch den Horizont im Bereich HPE Storage
            erweitern und neue Möglichkeiten sichtbar machen.
          </p>
          <p>
            – Von der Alletra MP bis hin zu GreenLake for Disaster Recovery –
          </p>
          <p>
            Gemeinsam mit Hewlett Packard Enterprise & Zerto werfen wir einen Blick auf Lösungen,
            die in einer Zeit der intensiven Datenerzeugung und Datennutzung wirklich zählen.
          </p>
          <p>
            Anschließend tauschen wir Bits gegen Bites und genießen die Aussicht
            mit Köstlichkeiten vom Grill.
          </p>

          <br />
          <p>
            <strong>Bitte registrieren Sie sich bis zum 29.08.2024.</strong>
          </p>
          <p>
            Sollte etwas dazwischenkommen und die Teilnahme nicht möglich sein,
            bitten wir Sie für die bessere Planbarkeit um eine Absage.
          </p>
          <p>Wir würden uns sehr freuen, wenn Sie dabei wären!!</p>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="userInput">
            <h3>Anmeldung</h3>
            <div
              style={{
                borderBottom: "2px solid gray",
                paddingBottom: "10px ",
                marginBottom: "10px",
              }}
            >
              <Checkbox
                style={{
                  border:
                    participationMissing && participationMissing !== null
                      ? "2px solid var(--warn)"
                      : "",
                }}
                onChange={() => {
                  setParticipation(true);
                }}
                onClick={() => {
                  setParticipationMissing(false);
                }}
                title="Ich werde an dem Event teilnehmen."
                id="trueParticipation"
                checked={participation === true}
              />

              <Checkbox
                style={{
                  border:
                    participationMissing && participationMissing !== null
                      ? "2px solid var(--warn)"
                      : "",
                }}
                onChange={() => {
                  setParticipation(false);
                }}
                onClick={() => {
                  setParticipationMissing(false);
                }}
                title="Leider ist es mir nicht möglich, an dem Event teilzunehmen."
                id="falseParticipation"
                checked={participation === false}
              />
            </div>
            <div>
              <div className="userPersonalData">
                <div>
                  <Checkbox
                    style={{
                      border:
                        genderMissing && genderMissing !== null
                          ? "2px solid var(--warn)"
                          : "",
                    }}
                    onClick={() => {
                      setGenderMissing(false);
                    }}
                    onChange={() => {
                      setGender("male");
                    }}
                    title="Herr"
                    id="genderMale"
                    checked={gender === "male"}
                  />
                  <Checkbox
                    style={{
                      border:
                        genderMissing && genderMissing !== null
                          ? "2px solid var(--warn)"
                          : "",
                    }}
                    onClick={() => {
                      setGenderMissing(false);
                    }}
                    onChange={() => {
                      setGender("female");
                    }}
                    title="Frau"
                    id="genderFemale"
                    checked={gender === "female"}
                  />
                </div>
                <Input
                  title="Vorname"
                  onChange={(e) => setFirstName(e.target.value)}
                  onClick={() => setFirstNameMissing(false)}
                  style={{
                    border: firstNameMissing ? "2px solid var(--warn)" : "",
                  }}
                />
                <Input
                  title="Nachname"
                  onChange={(e) => setSureName(e.target.value)}
                  onClick={() => {
                    setSureNameMissing(false);
                  }}
                  style={{
                    border: sureNameMissing ? "2px solid var(--warn)" : "",
                  }}
                />
              </div>
              <Input
                title="E-Mail"
                onClick={() => {
                  setEmailMissing(false);
                }}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                style={{
                  border: emailMissing ? "2px solid var(--warn)" : "",
                }}
              />
              <Input
                title="Unternehmen"
                onChange={(e) => setCompany(e.target.value)}
                onClick={() => {
                  setCompanyMissing(false);
                }}
                style={{
                  border: companyMissing ? "2px solid var(--warn)" : "",
                }}
              />
            </div>

            <Checkbox
              onChange={() => {
                setAllowPicture(!allowPicture);
              }}
              title="Ich erkläre mich damit einverstanden, dass im Rahmen der Veranstaltung Bilder und/oder Videos von meiner Person gemacht werden, ggf. zur Veröffentlichung verwendet und zu diesem Zwecke abgespeichert werden dürfen."
              id="allowPicture"
              checked={allowPicture === true}
            />
            <div className={`container ${isActive ? "active" : ""}`}>
              <button style={changeButton()} id="btn" onClick={register}>
                <p style={{ fontSize: "20px" }} id="btnText">
                  <strong>{buttonText}</strong>
                </p>
                <div className="check-box">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                    <path fill="transparent" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
        <img
          id="hiddenNav2"
          src={MedoLogo}
          alt="Logo"
          onClick={() => {
            navigate("/members");
          }}
        />
      </div>
      <Toast msg={toastMsg} visible={toast} type={toastType} />
    </>
  );
}
