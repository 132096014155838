// CSS
import "./assets/style/App.css";
import "./assets/style/registrationForm.css";
import "./assets/style/input.css";
import "./assets/style/card.css";
import "./assets/style/calendar.css";
import "./assets/style/footer.css";
import "./assets/style/header.css";
import "./assets/style/eventFilter.css";
import "./assets/style/animations.css";
import "./assets/style/about.css";
import "./assets/style/impressum.css";
import "./assets/style/table.css";
import "./assets/style/stepperForm.css";
import "./assets/style/toast.css";
import "./assets/style/typography.css";
// Mobile CSS
import "./assets/style/mobile.registrationForm.css";
import "./assets/style/mobile.input.css";
import "./assets/style/mobile.header.css";
import "./assets/style/mobile.footer.css";
import "./assets/style/mobile.eventFilter.css";
import "./assets/style/mobile.table.css";
// Themes CSS
import "./assets/style/theme.medo.css";
import "./assets/style/theme.light.css";
import "./assets/style/theme.dark.css";
// Libarys
import React, { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// Auth
import PrivateRoute from "./auth/privateRoute";
import { KeycloakContext } from "./auth/keycloak";
// Sentry
import * as Sentry from "@sentry/react";
//  Functions and types
import { Get } from "./assets/api";
import { Event } from "./assets/types";
// Images
import Logo from "./assets/images/medo.png";
//  Components
import RegistrationForm from "./pages/event/registration";
import InfoPage from "./pages/info";
import Footer from "./assets/footer";
import Header from "./assets/header";
import Events from "./pages/event/list";
import Impressum from "./assets/footer/impressum";
import AboutPagePartner from "./pages/partner/about";
import GalleryPage from "./pages/event/about";
import DocsPage from "./pages/docs";
import AdminEvent from "./pages/event/admin";
import EventMember from "./pages/member";
import StatusPage from "./pages/status";

function App(): JSX.Element {
  const { keycloak, profile } = useContext(KeycloakContext);
  const [allEvents, setAllEvents] = useState<Event[]>([]);
  const signUp = async () => {
    await keycloak?.login();
  };

  useEffect(() => {
    Get({ path: "event" })
      .then((events) => {
        if (events.status !== 500) {
          setAllEvents(events.data);
          console.log(profile, keycloak?.realmAccess, keycloak?.authenticated);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  {
    /* Temporary Until HEader is Public! */
  }
  useEffect(() => {
    const bodyElement = document.body;
    bodyElement.classList.remove("light");
    bodyElement.classList.remove("dark");
    bodyElement.classList.add("medocino");
  }, []);

  return (
    <div className="App">
      <Sentry.ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("location", "first");
          scope.setTag("anotherTag", "anotherValue");
        }}
        fallback={<StatusPage text={"SENTRY ERROR Boundary"} />}
      >
        {/* Temporary Just internal! */}
        {keycloak && profile ? (
          <Header />
        ) : (
          <img
            id="tempHeader"
            onClick={() => {
              keycloak?.login();
            }}
            src={Logo}
          />
        )}
        <Routes>
          <Route
            path="/"
            element={
              keycloak && profile ? (
                <Navigate to="/events" />
              ) : (
                <RegistrationForm />
              )
            }
          />

          <>
            <Route path="/error" element={<StatusPage text={"Fehler"} />} />
            <Route
              path="/login"
              element={
                <StatusPage
                  text={
                    <button
                      onClick={() => {
                        signUp();
                      }}
                    >
                      Login
                    </button>
                  }
                />
              }
            />
            <Route
              path="/partners"
              element={
                <PrivateRoute
                  component={
                    <InfoPage
                      title="Durch Stöbern Sie unsere Partner!"
                      body=""
                    />
                  }
                  roles={["default-roles-medocino"]}
                />
              }
            />
            <Route
              path="/partner/:uuid"
              element={
                <PrivateRoute
                  component={<AboutPagePartner />}
                  roles={["default-roles-medocino"]}
                />
              }
            />
            <Route
              path="/impressum"
              element={
                <PrivateRoute
                  component={<Impressum />}
                  roles={["default-roles-medocino"]}
                />
              }
            />
            <Route
              path="/events"
              element={
                <PrivateRoute
                  component={<Events allEvents={allEvents} />}
                  roles={["default-roles-medocino"]}
                />
              }
            />
            <Route
              path="/event/:uuid"
              element={
                <PrivateRoute
                  component={<GalleryPage />}
                  roles={["default-roles-medocino"]}
                />
              }
            />
            <Route
              path="/event/cancle/:event/:member"
              element={<StatusPage text={""} action="cancle" />}
            />
            <Route
              path="/event/success/:event"
              element={<StatusPage text={""} action="success" />}
            />
            <Route
              path="/login/:uuid"
              element={
                <PrivateRoute
                  component={<RegistrationForm />}
                  roles={["default-roles-medocino"]}
                />
              }
            />

            <Route
              path="/event/admin"
              element={
                <PrivateRoute
                  component={<AdminEvent />}
                  roles={["default-roles-medocino"]}
                />
              }
            />
            <Route
              path="/event/admin/:uuid/update"
              element={
                <PrivateRoute
                  component={<AdminEvent />}
                  roles={["default-roles-medocino"]}
                />
              }
            />
            <Route
              path="/event/admin/:uuid/member"
              element={
                <PrivateRoute
                  component={<EventMember />}
                  roles={["default-roles-medocino"]}
                />
              }
            />
            <Route
              path="/documentation"
              element={
                <PrivateRoute
                  component={<DocsPage />}
                  roles={["default-roles-medocino"]}
                />
              }
            />
          </>
        </Routes>
        {/* Temporary Just internal! */}
        {keycloak && profile ? <Footer /> : <></>}
      </Sentry.ErrorBoundary>
    </div>
  );
}
export default Sentry.withProfiler(App);
