import React from "react";
import { useNavigate } from "react-router-dom";
import { TCard } from "../types";

export default function Card({
  uuid,
  image,
  alt,
  header,
  body,
  companyImage,
  companyName,
  tOne,
  tTwo,
  bOne,
  bTwo,
}: TCard) {
  const navigate = useNavigate();

  return (
    <div className="cardContainer">
      <div className="card">
        <div className="cardBackground">
          <img src={image} alt={alt ? alt : "Background"} />
        </div>
        <div className="cardContent">
          <div className="cardHeader">
            {tOne ? (
              <div
                onClick={() => {
                  tOne.navigate
                    ? navigate(`/${tOne.navigate}`)
                    : console.log("");
                }}
                className="cardHeaderLeft"
              >
                {tOne.title}
              </div>
            ) : (
              <></>
            )}
            {tTwo ? (
              <div
                onClick={() => {
                  tTwo.navigate
                    ? navigate(`/${tTwo.navigate}`)
                    : console.log("");
                }}
                className="cardHeaderRight"
              >
                {tTwo.title}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="cardBody">
            <h2>{header}</h2>
            <p>{body}</p>
          </div>
          <div className="cardFooter">
            {bOne || companyName ? (
              <div
                onClick={() => {
                  bOne && bOne.navigate
                    ? navigate(`/${bOne.navigate}`)
                    : console.log("");
                }}
                className="bottomLeft"
              >
                <img src={companyImage} />
                <div className="bottomLeftText">{companyName}</div>
              </div>
            ) : (
              <></>
            )}
            {bTwo ? (
              <div
                onClick={() => {
                  bTwo.navigate
                    ? navigate(`/${bTwo.navigate}`)
                    : console.log("");
                }}
                className="bottomRight"
              >
                {bTwo.title}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-arrow-narrow-right"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l14 0" />
                  <path d="M15 16l4 -4" />
                  <path d="M15 8l4 4" />
                </svg>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
