import React, { useContext, useEffect, useState } from "react";
import {
  Event,
  FormSectionProps,
  FormStepProps,
  TagOption,
  User,
} from "../../assets/types";
import Input from "../../assets/input/input";
import Textarea from "../../assets/input/textArea";
import { Get, Post } from "../../assets/api";
import Table from "../../assets/table";
import { IoCloseOutline } from "react-icons/io5";
import { KeycloakContext } from "../../auth/keycloak";

function FormStep({
  stepNumber,
  label,
  isActive,
  isCompleted,
  onClick,
  ...rest
}: FormStepProps) {
  return (
    <li
      className={`text-center form-stepper-list ${
        isActive
          ? "form-stepper-active"
          : isCompleted
          ? "form-stepper-completed"
          : "form-stepper-unfinished"
      }`}
      onClick={onClick}
      {...rest}
    >
      <a className="mx-2">
        <span className={`form-stepper-circle ${isActive ? "" : "text-muted"}`}>
          <span>{stepNumber}</span>
        </span>
        <div className={`label ${isActive ? "" : "text-muted"}`}>{label}</div>
      </a>
    </li>
  );
}

function FormSection({
  title,
  inputFields,
  navigateToFormStep,
  activeStep,
  stepIndex,
  maxStep,
  createEvent,
}: FormSectionProps): JSX.Element {
  return (
    <section
      id={`step-${stepIndex}`}
      className={`form-step ${activeStep === stepIndex ? "" : "d-none"}`}
    >
      <h2 className="font-normal">{title}</h2>
      <div className="mt-3">{inputFields}</div>
      <div className="mt-3">
        {activeStep !== 1 && activeStep < maxStep ? (
          <button
            className="button btn-navigate-form-step"
            type="button"
            onClick={() => navigateToFormStep(activeStep - 1)}
          >
            Prev
          </button>
        ) : (
          <></>
        )}

        {activeStep === maxStep ? (
          <button
            className="button submit-btn"
            type="submit"
            onClick={createEvent}
          >
            Save
          </button>
        ) : (
          <button
            className="button btn-navigate-form-step"
            type="button"
            onClick={() => navigateToFormStep(activeStep + 1)}
          >
            Next
          </button>
        )}
      </div>
    </section>
  );
}

export default function CreateEventModal() {
  const [activeStep, setActiveStep] = useState(1);
  const maxStep = 4;
  const [categorys, setCategorys] = useState<TagOption[]>();
  const [event, setEvent] = useState<Event>({
    uuid: "",
    title: "",
    body: "",
    start: "",
    end: "",
    category: [],
    members: {
      participants: 0,
      maxParticipants: 0,
    },
    public: true,
  });

  const { keycloak } = useContext(KeycloakContext);

  const navigateToFormStep = (stepNumber: number) => {
    setActiveStep(stepNumber);
  };

  const closeModal = () => {
    navigateToFormStep(1);
    setEvent({
      uuid: "",
      title: "",
      body: "",
      start: "",
      end: "",
      category: [],
      members: {
        participants: 0,
        maxParticipants: 0,
      },
      public: true,
    });
    document.querySelector("dialog")?.close();
  };

  const createEvent = () => {
    Post({ path: "event/create", token: keycloak?.token, value: event })
      .then((response) => {
        console.log("Create the Event: ", response);
        closeModal();
      })
      .catch((err) => {
        console.error("Error creating event");
      });
  };

  useEffect(() => {
    Get({ path: "category" })
      .then((categoryList) => {
        setCategorys(categoryList.data);
        console.log("Set Category");
      })
      .catch((err) => {
        console.error("Error getting Category List");
      });
  }, []);

  const exampleUsers: User[] = [
    {
      uuid: "1",
      email: "user1@example.com",
      firstName: "John",
      sureName: "Doe",
      company: "ABC Inc.",
      participation: true,
      allowPicture: true,
    },
    {
      uuid: "2",
      email: "user2@example.com",
      firstName: "Jane",
      sureName: "Smith",
      company: "XYZ Corp.",
      allowPicture: true,
    },
    {
      uuid: "3",
      email: "user3@example.com",
      firstName: "Bob",
      sureName: "Johnson",
      company: "123 Industries",
      participation: true,
      allowPicture: true,
    },
  ];

  return (
    <dialog>
      <h1>
        <IoCloseOutline size={50} onClick={closeModal} />
        Create a new Event!
      </h1>
      <div className="multi-step-form-container">
        <ul className="form-stepper form-stepper-horizontal text-center mx-auto pl-0">
          <FormStep
            stepNumber={1}
            label="Basics"
            isActive={activeStep === 1}
            isCompleted={activeStep > 1}
            onClick={() => navigateToFormStep(1)}
          />
          <FormStep
            stepNumber={2}
            label="Category"
            isActive={activeStep === 2}
            isCompleted={activeStep > 2}
            onClick={() => navigateToFormStep(2)}
          />
          <FormStep
            stepNumber={3}
            label="Zeit"
            isActive={activeStep === 3}
            isCompleted={activeStep > 3}
            onClick={() => navigateToFormStep(3)}
          />
          <FormStep
            stepNumber={4}
            label="Member"
            isActive={activeStep === 4}
            isCompleted={activeStep > 4}
            onClick={() => navigateToFormStep(4)}
          />
        </ul>
        <div>
          <FormSection
            title="Basics"
            inputFields={
              <div>
                <Input
                  title="Name"
                  type="text"
                  onChange={(e) => {
                    setEvent((prevEvent) => ({
                      ...prevEvent,
                      title: e.target.value,
                    }));
                  }}
                />
                <Textarea
                  title="Beschreibung"
                  onChange={(e) =>
                    setEvent((prevEvent) => ({
                      ...prevEvent,
                      body: e.target.value,
                    }))
                  }
                />
              </div>
            }
            navigateToFormStep={navigateToFormStep}
            activeStep={activeStep}
            stepIndex={1}
            maxStep={maxStep}
          />
          <FormSection
            title="Category"
            inputFields={
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  maxWidth: "1000px",
                }}
              >
                <div className="category-section edit">
                  <h3>Available Categories</h3>
                  <div className="awards">
                    {categorys && (
                      <div className="selected-tags">
                        {categorys
                          .filter(
                            (tag) =>
                              !event.category?.find(
                                (selectedTag) => selectedTag.value === tag.value
                              )
                          )
                          .map((tag) => (
                            <div
                              key={tag.value}
                              className="tag"
                              style={{ backgroundColor: tag.color }}
                              onClick={() =>
                                setEvent((prevEvent) => ({
                                  ...prevEvent,
                                  category: [
                                    ...(prevEvent.category || []),
                                    tag,
                                  ],
                                }))
                              }
                            >
                              {tag.label}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="category-section">
                  <h3>Chosen Categories</h3>
                  <div className="awards">
                    <div className="selected-tags">
                      {event.category?.map((tag) => (
                        <div
                          key={tag.value}
                          className="tag"
                          style={{ backgroundColor: tag.color }}
                          onClick={() =>
                            setEvent((prevEvent) => ({
                              ...prevEvent,
                              category: (prevEvent.category || []).filter(
                                (selectedTag) => selectedTag.value !== tag.value
                              ),
                            }))
                          }
                        >
                          {tag.label}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            }
            navigateToFormStep={navigateToFormStep}
            activeStep={activeStep}
            stepIndex={2}
            maxStep={maxStep}
          />
          <FormSection
            title="Zeit"
            inputFields={
              <>
                <Input
                  title="Start"
                  type="datetime-local"
                  onChange={(e) => {
                    setEvent((prev) => ({
                      ...prev,
                      start: new Date(e.target.value).toISOString(),
                    }));
                  }}
                />
                <Input
                  title="End"
                  type="datetime-local"
                  onChange={(e) => {
                    setEvent((prev) => ({
                      ...prev,
                      end: new Date(e.target.value).toISOString(),
                    }));
                  }}
                />
              </>
            }
            navigateToFormStep={navigateToFormStep}
            activeStep={activeStep}
            stepIndex={3}
            maxStep={maxStep}
          />
          <FormSection
            title="Member"
            inputFields={
              <>
                <input
                  type="checkbox"
                  id="switch"
                  onClick={(e) => {
                    setEvent((prev) => ({
                      ...prev,
                      public: !event.public,
                    }));
                  }}
                  defaultChecked
                />
                <label className="labelToggle" htmlFor="switch"></label>
                {event.public ? <p>Öffentlich</p> : <p>Privat</p>}
                <Input
                  title="Maximal"
                  type="number"
                  onChange={(e) => {
                    setEvent((prev) => ({
                      ...prev,
                      members: {
                        participants: 0,
                        maxParticipants: parseInt(e.target.value),
                      },
                    }));
                  }}
                />
                {event.public ? (
                  <></>
                ) : (
                  <Table rows={exampleUsers} what="Member" />
                )}
              </>
            }
            navigateToFormStep={navigateToFormStep}
            activeStep={activeStep}
            stepIndex={4}
            maxStep={maxStep}
            createEvent={createEvent}
          />
        </div>
      </div>
    </dialog>
  );
}
