import React, { useEffect, useState } from "react";
import { TGallery, TParagraph, TPartner } from "../../assets/types";
import { useParams } from "react-router-dom";
import { Get } from "../../assets/api";
import Input from "../../assets/input/input";
import Textarea from "../../assets/input/textArea";

export function ParagraphItem(
  paragraph: TParagraph,
  index: number
): JSX.Element {
  const renderImage = (img: TGallery["img"]) => (
    <div className="aboutContainer" key={index}>
      <img src={img.src} alt={img.alt} />
      <div>
        {paragraph.title && <h1>{paragraph.title}</h1>}
        {paragraph.description && <h3>{paragraph.description}</h3>}
        {Array.isArray(paragraph.content) ? (
          paragraph.content.map((subParagraph, subIndex) => (
            <ParagraphItem {...subParagraph} key={`${index}-${subIndex}`} />
          ))
        ) : (
          <p>{paragraph.content}</p>
        )}
      </div>
    </div>
  );

  return (
    <div key={index} className="aboutContainer">
      {paragraph.image ? (
        renderImage(paragraph.image.img)
      ) : (
        <div>
          {Array.isArray(paragraph.content) ? (
            paragraph.content.map((subParagraph, subIndex) => (
              <ParagraphItem {...subParagraph} key={`${index}-${subIndex}`} />
            ))
          ) : (
            <p>{paragraph.content}</p>
          )}
        </div>
      )}
    </div>
  );
}
export default function AboutPagePartner(): JSX.Element {
  const [partner, setPartner] = useState<TPartner>();
  const { uuid } = useParams();

  useEffect(() => {
    console.log(uuid);
    Get({ path: `partner/${uuid}` })
      .then((response) => {
        console.log(response.data);
        setPartner(response.data);
      })
      .catch((err: any) => {
        console.error(err);
      });
  }, [uuid]);

  if (partner) {
    return (
      <div className="content">
        <div className="thumbnail-container">
          <img src={partner.about?.thumb.src} alt={partner.about?.thumb.alt} />
          <h1>{partner.name}</h1>
        </div>
        <div className="awards">
          {partner.category ? (
            <div className="selected-tags">
              {partner.category.map((tag) => (
                <div
                  key={tag.value}
                  className="tag"
                  style={{
                    backgroundColor: tag.color,
                  }}
                >
                  {tag.label}
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
        {partner.about ? (
          <div className="aboutBody">
            {partner.about.about.map((paragraph, index) => (
              <ParagraphItem {...paragraph} key={index} />
            ))}
          </div>
        ) : (
          <></>
        )}

        <div className="awards">
          {partner.awards ? (
            partner.awards.map((award, index) => (
              <div className="awardItem" key={index}>
                <img src={award.image?.img.src} alt={award.image?.img.alt} />
                {typeof award.content === "string" ? (
                  <p>{award.content}</p>
                ) : (
                  <></>
                )}
              </div>
            ))
          ) : (
            <></>
          )}
          {partner.partnershipStatus ? (
            partner.partnershipStatus.map((status, index) => (
              <div className="awardItem" key={index}>
                <img src={status.image?.img.src} alt={status.image?.img.alt} />
                {typeof status.content === "string" ? (
                  <p>{status.content}</p>
                ) : (
                  <></>
                )}
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
        <div className="aboutBody">
          <div className="aboutContainer" style={{ flexDirection: "column" }}>
            <h3>Interesse Geweckt?</h3>
            <h5>Dann Kontaktieren Sie uns!</h5>
            <Input title="Name" type="text" />
            <Input title="E-Mail" type="text" />
            <Textarea title="Anfrage" rows={5} />
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
