import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { KeycloakContext } from "./keycloak";

interface IAuthRoute {
  component: JSX.Element;
  roles: string[];
}

const checkRole = (roles: string[], role: string[]) => {
  console.warn(
    "ROLES: ",
    roles,
    role,
    roles.includes(role[0]) || roles.includes(role[1])
  );
  return roles.includes(role[0]) || roles.includes(role[1]);
};

const PrivateRoute = ({ component, roles }: IAuthRoute) => {
  const { keycloak } = useContext(KeycloakContext);

  if (
    keycloak &&
    keycloak.realmAccess &&
    keycloak.authenticated &&
    checkRole(keycloak.realmAccess.roles, roles)
  ) {
    return component;
  } else if (keycloak && !keycloak.authenticated) {
    if (keycloak.authenticated) {
    }
    return <Navigate to={{ pathname: "/login" }} />;
  } else {
    console.log("No Keycloak connection");
    return <Navigate to={{ pathname: "/error" }} />;
  }
};

export default PrivateRoute;
