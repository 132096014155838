import React, { ChangeEvent, useEffect, useState } from "react";
import { BsGrid } from "react-icons/bs";
import { AiOutlineSearch, AiOutlineCloseCircle } from "react-icons/ai";
import { TPartner, TagOption } from "../types";
import { Get } from "../api";
import Input from "../input/input";

export default function PartnerFilter({
  partnerList,
  changeFilter,
}: {
  partnerList: TPartner[];
  changeFilter: (partner: TPartner[]) => void;
}): JSX.Element {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [prevFilteredPartners, setPrevFilteredPartners] = useState<TPartner[]>(
    []
  );
  const [allTagOptions, setAllTagOptions] = useState<TagOption[]>([]);

  const tagOptions = allTagOptions.filter(
    (tag) => !selectedTags.includes(tag.value)
  );

  const toggleTag = (tag?: string) => {
    console.log(tag);
    setSelectedTags((prevTags) =>
      prevTags.includes(tag!)
        ? prevTags.filter((prevTag) => prevTag !== tag)
        : [...prevTags, tag!]
    );
  };

  const toggleOptions = () => {
    setShowOptions((prevShowOptions) => !prevShowOptions);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredPartners = partnerList.filter((partner) => {
    const nameMatch = partner.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    if (partner.category && selectedTags.length !== 0) {
      const categoryMatch = partner.category.some((category) =>
        selectedTags.includes(category.value)
      );

      return nameMatch && categoryMatch;
    }

    return nameMatch;
  });

  const deepEqual = (obj1: any, obj2: any): boolean => {
    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    if (!deepEqual(filteredPartners, prevFilteredPartners)) {
      setPrevFilteredPartners(filteredPartners);
      changeFilter(filteredPartners);
    }
  }, [filteredPartners, prevFilteredPartners]);

  useEffect(() => {
    Get({ path: "category" }).then((categorys) => {
      console.log(categorys);
      if (categorys.status !== 500) {
        setAllTagOptions(categorys.data);
      } else {
        setAllTagOptions([]);
      }
    });
  }, []);

  return (
    <div className="filterBar" style={{ gridArea: "filterNav" }}>
      <Input
        title={<AiOutlineSearch size={25} />}
        onChange={handleInputChange}
        type="text"
      />

      <BsGrid onClick={toggleOptions} size={25} />
      <div className="multiselect-tag-list">
        {showOptions && (
          <div className="tag-container">
            {tagOptions.map((tag) => (
              <div
                key={tag.value}
                className="tag"
                onClick={() => toggleTag(tag.value)}
                style={{ backgroundColor: tag.color }}
              >
                {tag.label}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="selected-tags">
        {selectedTags.map((tag) => (
          <div
            key={tag}
            className="tag selected"
            onClick={() => toggleTag(tag)}
            style={{
              backgroundColor: allTagOptions.find((t) => t.value === tag)
                ?.color,
            }}
          >
            <AiOutlineCloseCircle size={15} /> {tag}
          </div>
        ))}
      </div>
    </div>
  );
}
