import React, { useContext } from "react";
import ReactDOM from "react-dom/client";
import "./assets/style/index.css";
import App from "./App";
import reportWebVitals from "./assets/defaults/reportWebVitals";
import {
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { KeycloakAuth } from "./auth/keycloak";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://91241f5038fc4a8c9cb0d78a43138e3d@sentry.genesis.exanio.cloud/29",
  integrations: [
    new Sentry.BrowserTracing({
      // routingInstrumentation: Sentry.reactRouterV6Instrumentation(
      //   React.useEffect,
      //   useLocation,
      //   useNavigationType,
      //   createRoutesFromChildren,
      //   matchRoutes
      // ),
    }),
  ],
  tracesSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <KeycloakAuth>
    <Router>
      {/* <SentryRoutes> */}
      <App />
      {/* </SentryRoutes> */}
    </Router>
  </KeycloakAuth>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
