import React, { useEffect, useState } from "react";
import EventFilter from "../../assets/header/eventFilter";
import { Event, TViewTypes } from "../../assets/types";
import { Get } from "../../assets/api";
import { useNavigate } from "react-router-dom";
import CreateEventModal from "./newModal";
import Table from "../../assets/table";
import { MdGroup } from "react-icons/md";

export default function AdminEvent(): JSX.Element {
  const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
  const [allEvents, setAllEvents] = useState<Event[]>([]);
  const [view, setView] = useState<TViewTypes>("widget");
  const changeView = (viewType: TViewTypes) => {
    setView(viewType);
  };
  const navigate = useNavigate();
  const changePath = (url: string): void => {
    navigate(url);
  };
  const eventArray = (events: Event[]): void => {
    setFilteredEvents(events);
  };

  useEffect(() => {
    Get({ path: "event" })
      .then((events) => {
        if (events.status !== 500) {
          setAllEvents(events.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="content">
      <p>Event Admin</p>
      <EventFilter
        allEvents={allEvents}
        eventArray={eventArray}
        createEvent={true}
        changeView={changeView}
      />
      <Table
        rows={filteredEvents}
        what="Event"
        actions={[
          {
            label: "Mitglieder",
            button: <MdGroup size={50} />,
            onClick: (uuid) => changePath(`${uuid}/member`),
          },
        ]}
      />
      <CreateEventModal />
    </div>
  );
}
