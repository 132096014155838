import React, { useState } from "react";
import Calendar from "../../assets/calendar";
import Card from "../../assets/cards";
import MedoIcon from "../../assets/images/meod_small.png";
import EventFilter from "../../assets/header/eventFilter";
import { Event, TViewTypes } from "../../assets/types";
import Table from "../../assets/table";
export default function Events({
  allEvents,
}: {
  allEvents: Event[];
}): JSX.Element {
  const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [view, setView] = useState<TViewTypes>("widget");

  const eventArray = (events: Event[]): void => {
    setFilteredEvents(events);
  };
  const changeCalendarVisibility = () => {
    setShowCalendar(!showCalendar);
  };
  const changeView = (viewType: TViewTypes) => {
    setView(viewType);
  };

  return (
    <div className={showCalendar ? "grid" : ""}>
      <EventFilter
        allEvents={allEvents}
        eventArray={eventArray}
        changeCalendarVisibility={changeCalendarVisibility}
        changeView={changeView}
      />
      <div
        className="content"
        style={{
          gridArea: "calendar",
          display: showCalendar ? "block" : "none",
        }}
      >
        <Calendar eventList={filteredEvents} />
      </div>
      <div
        className="content"
        style={{
          gridArea: "events",
        }}
      >
        {view === "widget" ? (
          filteredEvents.map((event: Event, index: number) =>
            event.start && new Date(event.start) >= new Date() ? (
              <Card
                uuid={event.uuid}
                key={index}
                body={event.body}
                companyImage={MedoIcon}
                companyName="medocino"
                header={event.title}
                tOne={{
                  title: "Details",
                  navigate: `event/${event.uuid}`,
                }}
                tTwo={{
                  title: `${event.members.participants} /${event.members.maxParticipants}`,
                  navigate: `event/${event.uuid}`,
                }}
                bTwo={{
                  title: "Join",
                  navigate: `login/${event.uuid}`,
                }}
                image="https://png.pngtree.com/thumb_back/fw800/back_pic/05/08/10/69597aa7e2441d0.jpg"
              />
            ) : (
              <Card
                key={index}
                uuid={event.uuid}
                body={event.body}
                companyImage={MedoIcon}
                companyName="medocino"
                header={event.title}
                tOne={{
                  title: "Gallery",
                  navigate: `event/${event.uuid}`,
                }}
                image="https://png.pngtree.com/thumb_back/fw800/back_pic/05/08/10/69597aa7e2441d0.jpg"
              />
            )
          )
        ) : (
          <Table rows={filteredEvents} what="Event" />
        )}
      </div>
    </div>
  );
}
